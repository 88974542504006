import request from '@/utils/request'

//裱花库存查询   分页列表
export function storeQueryAPI(params) {
  return request({
    url: '/api/system/decoration/store/report/listBillStoreQuery',
    method: 'get',
    timeout: 30000,
    params
  })
}

//裱花库存明细查询
export function getStoreBookDetail(params) {
  return request({
    url: '/api/system/decoration/store/report/getStoreBookDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//裱花库存  合计行
export function listShopInventoryQuerySummary(params) {
  return request({
    url: '/api/system/decoration/store/report/listBillStoreQuerySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}


